div.project-container {
  position: relative;
  max-width: 500px;
  max-height: 450px;
  flex: 1 0 33.33333%;
  margin: 20px;
}

div.project-container:hover .project-image {
  opacity: 0.3;
}

div.project-container:hover .project-image-overlay {
  opacity: 1;
}

img.project-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  border-radius: 10px;
}

div.project-image-overlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  /* bottom: 0; */
  top: 50%;
  left: 50%;
  width: 70%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

div.project-image-overlay > h3 {
    padding-bottom: 5px;
}

