div.hero-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 600px;
  height: 100vh;
  background-color: rgb(240, 241, 241);
}

div.hero-text {
  width: 50%;
  max-height: 100%;
  padding: 20px 20px 20px 50px;
}

div.hero-text > h1.hero-text-name {
  color: #52c8c5;
}

div.hero-art {
  width: 50%;
  padding: 15px;
}

/* Animation for Fade In */
.fade-in-animation {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
