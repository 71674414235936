div.nav-bar {
    width: 100%;
    height: 70px;
    position: sticky;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    top: 0px;
    border-bottom: .5px solid lightgray;
    background-color: white;
    z-index: 1;
}

h1.nav-name {
    margin-right: 10px;
    margin-left: 10px;
}

hr.nav-divider {
    margin-right: 10px;
}

div.nav-bar > button.nav-chat {
    margin-left: auto;
}

