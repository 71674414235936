section.skills-section {
    padding: 20px 0 20px 0;
    background-color: rgb(240, 241, 241);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  

div.skills-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1000px;
}

div.skills-heading {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

div.skills-heading > svg {
  flex: 1 0 100%;
  text-align: center;
}

div.skills-heading > h1 {
  flex: 1 0 100%;
  text-align: center;
}

div.skills-heading > p {
  flex: 1 0 100%;
  text-align: center;
}

