div.projects-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

div.projects-heading {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

div.projects-heading > svg {
    flex: 1 0 100%;
    text-align: center;
}

div.projects-heading > h1 {
    flex: 1 0 100%;
    text-align: center;
}

div.project-container > p {
    flex: 1 0 100%;
    text-align: center;
}


section {
    padding: 20px 0 20px 0;
}
