div.skill-container {
  height: 50px;
  width: 400px;
  border-radius: 10px;
  display: flex;
  margin: 20px;
  flex: 1 0 auto;
  background-color: white;
  box-shadow: 0px 7px 21px 3px rgba(0, 0, 0, 0.1);
}

div.skill-container > h4 {
  position: absolute;
  margin: 12px 10px;
}

div.skill-progress {
  background: linear-gradient(
    90deg,
    rgba(172, 223, 215, 1) 0%,
    rgba(86, 199, 178, 1) 66%,
    rgba(82, 200, 197, 1) 100%
  );

  border-radius: 10px;
}
