section.contact-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.contact-art {
  width: 50%;
  padding: 15px;
}

div.contact-form {
  margin: 20px;
}

button.contact-submit-btn {
  margin-top: 10px;
}
